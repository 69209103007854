import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Flex, useToast } from '@chakra-ui/react';
import { selectorSelectedGroupOrder } from '@src/features/orders/redux/extra-store/selectors';
import { FlightDroneInfo, PopupOrdersList, PopupWrapper } from '../components';
import { ReactComponent as TakeIcon } from '@src/features/orders/assets/takeOff.svg';
import { ReactComponent as LandIcon } from '@src/features/orders/assets/land.svg';
import { ReactComponent as ReturnIcon } from '@src/features/orders/assets/return.svg';
import { ReactComponent as PauseIcon } from '@src/features/orders/assets/pause.svg';
import { EmergencyStatusEnum } from '@src/features/orders/models/EmergencyModel';
import { OrderModel } from '@src/features/orders/models/OrderModel';
import { setGoogleMapCenter } from '@src/components/google-map/redux/actions';
import { useSendCommandMutation } from '@src/features/orders/redux/api';
import { DroneCamera } from '../../en-route-order-page/components';

const GroupOrderEnRoutePopup: React.FC = () => {

  const toast = useToast();
  const dispatch = useDispatch();
  const selected = useSelector(selectorSelectedGroupOrder);
  const [sendCommand, { isLoading }] = useSendCommandMutation();

  const onSendCommand = async (command: EmergencyStatusEnum): Promise<void> => {
    if (selected && selected.missionId) {
      sendCommand({ missionId: selected.missionId, command }).unwrap()
        .then(() => {
          toast({ 
            status: 'success', 
            isClosable: true, 
            title: 'Your request was submitted',  
          });
        })
        .catch(() => {
          toast({ 
            status: 'error', 
            isClosable: true, 
            title: 'Something went wrong',  
          });
        });
    }
  };

  const onSelectGroupOrder = (order: OrderModel): void => {
    dispatch(setGoogleMapCenter({
      lat: Number(order.deliveryLocation.latitude),
      lng: Number(order.deliveryLocation.longitude),
    }));
  };

  return (
    <PopupWrapper title={`Drone - (${selected?.drone.serialNumber})`}>
        
      <PopupOrdersList onClick={onSelectGroupOrder} />

      <FlightDroneInfo />

      <Divider my='18px' borderColor='gray' />

      <Flex gap='10px'>
        <Button 
          disabled
          colorScheme='blackAlpha' 
          leftIcon={<TakeIcon width='24px' />}
          isLoading={isLoading}
          onClick={(): Promise<void> => onSendCommand(EmergencyStatusEnum.CONTINUE)}> 
          TAKE OFF 
        </Button>

        <Button 
          colorScheme='blackAlpha' 
          leftIcon={<LandIcon width='24px' />}
          isLoading={isLoading}
          onClick={(): Promise<void> => onSendCommand(EmergencyStatusEnum.LAND)}> 
          LAND 
        </Button>
      </Flex>

      <Flex mt='10px' gap='10px'>
        <Button 
          colorScheme='blackAlpha' 
          leftIcon={<ReturnIcon width='24px' />}
          isLoading={isLoading}
          onClick={(): Promise<void> => onSendCommand(EmergencyStatusEnum.RETURN)}> 
          RETURN 
        </Button>

        <Button 
          colorScheme='blackAlpha' 
          leftIcon={<PauseIcon width='24px' />}
          isLoading={isLoading}
          onClick={(): Promise<void> => onSendCommand(EmergencyStatusEnum.PAUSE)}> 
          PAUSE 
        </Button>
      </Flex>

      <Divider my='18px' borderColor='gray' />

      <DroneCamera droneSerialNumber={selected?.drone?.serialNumber} />
      
    </PopupWrapper>
  );
};

export default GroupOrderEnRoutePopup;