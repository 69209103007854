import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { WebSocketContext } from '@src/context/ws-context';
import { iceServers } from './iceServers';
import { WSRequestEnum } from '@src/context/ws-context/types';


const useDroneCamera = (droneSerialNumber: string) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isLoading, setIsLoading] = React.useState(false);
  
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const peerConnections = React.useRef<RTCPeerConnection | null>(null);

  const { getWS, subscribe, unsubscribe } = React.useContext(WebSocketContext);

  const initWebRTC = async (): Promise<void> => {
    try {
    // Create peer connection
      peerConnections.current = new RTCPeerConnection({ iceServers });

      // Set the direction to 'recvonly'
      peerConnections.current.addTransceiver('video', { direction: 'recvonly' }).receiver.track,
      peerConnections.current.addTransceiver('audio', { direction: 'recvonly' }).receiver.track,

      // Handle incoming stream
      peerConnections.current.ontrack = (event: RTCTrackEvent) => {
        if (videoRef.current) {
          videoRef.current.srcObject = event.streams[0];
        }
      };

      // Create offer to receive only
      const offer = await peerConnections.current.createOffer();
      await peerConnections.current.setLocalDescription(offer);

      // Send offer to the signaling server
      const webSocket = getWS();
      const payload = {
        type: WSRequestEnum.WEBRTC,
        command: 'offer',
        data: offer.sdp,
        serialNumber: droneSerialNumber,
      };
      webSocket.send(JSON.stringify(payload));

      // Send local ICE candidates to the signaling server
      peerConnections.current.onicecandidate = (event) => {
        if (event.candidate) {
          const payload = { 
            type: "WEBRTC", 
            command: "ice_candidate", 
            data: JSON.stringify(event.candidate), 
            serialNumber: droneSerialNumber,
          };

          webSocket.send(JSON.stringify(payload));
        }
      };

      peerConnections.current.oniceconnectionstatechange = () => {
        console.log('ICE Connection State:', peerConnections.current?.iceConnectionState);
        if (peerConnections.current?.iceConnectionState === 'failed') {
          console.error('ICE connection failed. Checking network and TURN/STUN servers.');
        }
      };
    } catch (error) {
      console.error("Error initializing WebRTC:", error);
    }
  };

  const onStopVideo = () => {
    const webSocket = getWS();

    const payload = {
      type: WSRequestEnum.WEBRTC,
      command: 'stop',
      data: null,
      serialNumber: droneSerialNumber,
    };
    webSocket.send(JSON.stringify(payload));
    peerConnections.current?.getTransceivers().forEach(transceiver => transceiver.stop());
    peerConnections.current?.close();
    peerConnections.current = null;
    onClose();
  };

  const onStartVideo = async (): Promise<void> => {
    setIsLoading(true);
    onOpen();
    await initWebRTC();
  };


  
  const getAnswerFromDrone = async (answer: RTCSessionDescriptionInit): Promise<void> => {
    if (peerConnections.current) {
      await peerConnections.current.setRemoteDescription(new RTCSessionDescription(answer));
    }
  };

  const getIceCandidateFromDrone = (remoteCandidate: RTCIceCandidateInit): void => {
    if (peerConnections.current) {
      const current = { ...remoteCandidate, candidate: remoteCandidate.candidate?.trim() };
      peerConnections.current.addIceCandidate(new RTCIceCandidate(current)).catch(error => console.error("Error adding ICE candidate:", error));
    }
  };


  React.useEffect(() => {
    const answerSubscription = (message: any) => {
      getAnswerFromDrone({ type: 'answer', sdp: message.data });
    };
    const iceCandidateSubscription = (message: any) => {
      getIceCandidateFromDrone(JSON.parse(message.data));
    };

    subscribe('answer', answerSubscription);
    subscribe('ice_candidate', iceCandidateSubscription);

    return () => {
      unsubscribe('answer', answerSubscription);
      unsubscribe('ice_candidate', iceCandidateSubscription);
    };
  }, [subscribe, unsubscribe]);

  React.useEffect(() => {

    const videoElement = videoRef.current;

    if (!videoElement) return;

    const handleWaiting = () => setIsLoading(true);
    const handlePlaying = () => setIsLoading(false);
    const handleError = () => setIsLoading(false);

    videoElement.addEventListener('waiting', handleWaiting);
    videoElement.addEventListener('playing', handlePlaying);
    videoElement.addEventListener('canplay', handlePlaying);
    videoElement.addEventListener('error', handleError);

    return () => {
      videoElement.removeEventListener('waiting', handleWaiting);
      videoElement.removeEventListener('playing', handlePlaying);
      videoElement.removeEventListener('canplay', handlePlaying);
      videoElement.removeEventListener('error', handleError);
    };
  }, [isOpen]);

  return {
    isOpen,
    onStopVideo,
    onStartVideo,
    isLoading,
    videoRef,
  };

  
};

export default useDroneCamera;