import React from 'react';
import { useNavigate } from 'react-router-dom';
import { withDefaultLayout } from '@src/layouts';
import { Flex, useToast } from '@chakra-ui/react';

import Map from './assign-map';
import Sidebar from './assign-sidebar';
import { useAssignDroneMutation, useModifyRouteMutation } from '../../redux/api';
import { useSelector } from 'react-redux';
import { selectSelectedDrone } from '@src/features/drones/redux/extra-store/selectors';
import { useDrones } from '@src/features/drones/hooks';
import { TabActionButtons } from '../../components';
import { useMissionOrder } from '../../hooks';
import { convertDroneToWP, convertOrderToWP } from '../../shared';
import { DroneStatusEnum } from '@src/features/drones/models';


const AssignDronePage: React.FC = () => {

  const toast = useToast();
  const navigate = useNavigate();
  const { drones, isLoading: isDronesLoading } = useDrones(DroneStatusEnum.IDLE);
  const [assignDrone, { isLoading }] = useAssignDroneMutation();
  const [modifyRoute, { isLoading: isModifyLoading }] = useModifyRouteMutation();
  const { 
    newOrders, isNewOrdersLoading,
    missionOrders, isMissionOrdersLoading, 
    onToggleOrder, onDragDropMissionOrder,
  } = useMissionOrder();

  const selectedDrone = useSelector(selectSelectedDrone);

  const goBack = () => navigate('/app/orders');

  const goForth = async () => {

    if (!selectedDrone) return;

    const missionIds = missionOrders.map((mOrder) => mOrder.id);

    // TO DO: refactor this line
    assignDrone({ droneId: selectedDrone?.id, orders: missionIds }).unwrap()
      .then((response) => {
        const waypoints =  [
          convertDroneToWP(selectedDrone), 
          ...missionOrders.map((order) => convertOrderToWP(order)),
          convertDroneToWP(selectedDrone),
        ];
        modifyRoute({ missionId: response.missionId, waypoints }).unwrap().then(() => {
          navigate(`/app/orders/modifyRoute/${response.missionId}`);
        });
      })
      .catch((e) => {
        toast({ 
          position: 'top',
          status: 'error', 
          isClosable: true, 
          title: e?.response?.data?.message || 'ERROR',  
        });
      });
  };

  return (
    <Flex width='100%'>
      
      <Sidebar 
        drones={drones}
        isDronesLoading={isDronesLoading}

        newOrders={newOrders}
        isNewOrdersLoading={isNewOrdersLoading}

        missionOrders={missionOrders} 
        isMissionOrdersLoading={isMissionOrdersLoading}

        onToggleOrder={onToggleOrder}
        onDragDropMissionOrder={onDragDropMissionOrder} />

      <Map 
        drones={drones}
        newOrders={newOrders}
        isLoadingDrone={isDronesLoading}
        missionOrders={missionOrders} 
        selectedDrone={selectedDrone}  />

      <TabActionButtons 
        goBack={goBack}
        goBackText='CANCEL'

        goForth={goForth} 
        goForthText='ASSIGN DRONE'
        
        isLoading={isLoading || isModifyLoading} />

    </Flex>
  );
};

export default withDefaultLayout(AssignDronePage);