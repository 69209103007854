import React from 'react';
import { Flex, IconButton, Skeleton } from '@chakra-ui/react';
import { DroneCameraProps } from './types';
import { useDroneCamera } from '../../hooks';

const DroneCamera: React.FC<DroneCameraProps> = (props) => {
  const { droneSerialNumber } = props;

  const { isOpen, isLoading, onStartVideo, onStopVideo, videoRef } = useDroneCamera(droneSerialNumber || '');

  React.useEffect(() => {
    return () => onStopVideo();
  }, []);


  if (isOpen) {
    return (
      <Flex 
        p='12px'
        w='100%' 
        direction='column'
        position='relative'>
        
        {isLoading &&
          <Skeleton 
            position='absolute'
            top={0}
            left={0}
            right={0}
            width='100%' 
            height='190px'
            startColor='blackAlpha.400'
            endColor='blackAlpha.700' /> 
        }
          
        <video
          autoPlay 
          playsInline
          width='100%' 
          height='300px'
          ref={videoRef}
        />
        
        <IconButton 
          mt='12px'
          mx='auto'
          width='50px'
          height='50px' 
          colorScheme='red'
          aria-label='Stop Video'
          isLoading={isLoading}
          onClick={onStopVideo} 
        />
      </Flex>
    );
  }
  return (
    <IconButton
      mx='auto'
      width='50px'
      height='50px'
      variant='brand'
      aria-label='Start Video'
      isLoading={isLoading}
      onClick={onStartVideo}
    />
  );
};

export default DroneCamera;
