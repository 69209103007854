import React from 'react';
import { OrderFormLoading } from './components';
import { SubmitHandler } from 'react-hook-form';
import { Flex, useToast } from '@chakra-ui/react';
import { withDefaultLayout } from '@src/layouts';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IOrderForm, OrderForm, OrderMap, TabActionButtons } from '../../components';
import { useLazyGetOrderQuery, useUpdateOrderMutation } from '../../redux/api';
import { IPutUpdateOrderRequest, IPutUpdateOrderResponse } from '../../redux/types';
import { setFormattedAddress } from '../../redux/extra-store/actions';
import { setGoogleMapCenter, setGoogleMapLatitude, setGoogleMapLongitude } from '@src/components/google-map/redux/actions';
import { selectGoogleMapLatitude, selectGoogleMapLongitude } from '@src/components/google-map/redux/selectors';


const UpdateOrderPage: React.FC = () => {

  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderId } = useParams<{ orderId?: string }>();

  const [getOrder, { isLoading: isGetOrderLoading }] = useLazyGetOrderQuery();
  const [updateOrder, { isLoading: isUpdateOrderLoading }] = useUpdateOrderMutation();

  const lat = useSelector(selectGoogleMapLatitude);
  const lng = useSelector(selectGoogleMapLongitude);
  const [order, setOrder] = React.useState<IPutUpdateOrderResponse | null>(null);


  const onUpdateOrder: SubmitHandler<IOrderForm> = (form) => {
    const { address, deliveryAltitude, ...rest } = form;

    const payload: IPutUpdateOrderRequest  ={
      id: Number(orderId),
      ...rest,
      deliveryLocation: {
        address,
        latitude: String(lat),
        longitude: String(lng),
        altitude: deliveryAltitude,
      }
    };

    updateOrder(payload).unwrap()
      .then(() => navigate('/app/orders'))
      .catch((e) => {
        toast({
          status: 'error',
          isClosable: true,
          title: e?.message || 'Something went wrong'
        });
      });
  };

  const fetchOrderById = React.useCallback((id: string) => {
    getOrder({ id }).unwrap()
      .then((response) => setOrder(response))
      .catch((e) => {
        toast({
          status: 'error',
          isClosable: true,
          title: e?.message || 'Something went wrong',
          description: 'In 8 seconds, we will redirect you to the orders page. The service is currently unavailable'
        });
        setTimeout(() => navigate('/app/orders'), 8000);
      });
  }, []);



  React.useEffect(() => {
    if (orderId) {
      fetchOrderById(orderId);
    }
  }, [fetchOrderById, orderId]);


  React.useEffect(() => {
    if (order) {
      const { deliveryLocation: { address, latitude, longitude } } = order;
      dispatch(setFormattedAddress(address));
      dispatch(setGoogleMapLatitude(Number(latitude)));
      dispatch(setGoogleMapLongitude(Number(longitude)));
      dispatch(setGoogleMapCenter({ lat: Number(latitude), lng: Number(longitude) }));
    }
  }, [order]);


  const defaultValues: IOrderForm | undefined = React.useMemo(() => {
    if (order) {
      return {
        speed: order?.speed,
        altitude: order?.altitude,
        clientName: order?.clientName,
        packageContent: order?.packageContent,
        deliveryMethod: order?.deliveryMethod,
        address: order?.deliveryLocation?.address,
        deliveryAltitude: order?.deliveryLocation?.altitude
      };
    }
  }, [order]);
  
  return (
    <Flex w='100%'>

      <Flex 
        p='12px'
        w='100%' 
        maxW='400px'
        direction='column'
        bgColor='block.cloud'>
        {isGetOrderLoading || !defaultValues 
          ? <OrderFormLoading /> 
          : <OrderForm 
            title='UPDATE ORDER' 
            onSubmit={onUpdateOrder}
            defaultValues={defaultValues}>
            <TabActionButtons
              goBackText='CANCEL'
              goForthText='UPDATE ORDER'
              isLoading={isUpdateOrderLoading}
              goForth={() => {}}
              goBack={() => navigate('/app/orders')}  />
          </OrderForm>}
      </Flex>

      <OrderMap />

    </Flex>
  );
};

export default withDefaultLayout(UpdateOrderPage);